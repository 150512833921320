const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			se: `Välkommen till hygienutbildningen. Jag är produktionsledaren och kommer att hjälpa dig genom spelet.`,
			en: `Welcome to the hygiene training. I'm your production leader, and I'll help you through the game.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-2',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-1 coat-1'],
		text: {
			se: `Den här utbildningen handlar om hur du kan hjälpa oss att upprätthålla våra höga hygienstandarder i våra vita zoner – alltså våra produktionsområden.`,
			en: `This training is about how you can help us maintain our high hygiene standards in our white zones - that is, our production areas.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-3',
		showProgressBar: false,
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			se: `I det här spelet får du poäng för att lösa uppgifter. Ju färre fel du gör, desto fler poäng får du.`,
			en: `In this game you'll get points for solving tasks. The fewer mistakes you make, the more points you get.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-4',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			se: `Medan du får poäng får du också stjärnor. Högst upp på skärmen ser du hur många poäng och stjärnor du har.`,
			en: `As you get points, you'll also gain stars. At the top of your screen, you can see how many points and stars, you have.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-welcome-5',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			se: `Du behöver tre stjärnor för att gå vidare till nästa modul.`,
			en: `You need to get three stars to advance to the next module.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-first-task',
		background: 'outside',
		backgroundModifier: 'bottom',
		characters: ['manager pose-2 coat-2'],
		text: {
			se: `Första uppgiften handlar om att vara rätt klädd.`,
			en: `The first task is about being dressed properly.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-hair-snoods',
		layout: 's1-m1-hair-snoods',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			se: `Vilket är rätt sätt att använda hår- och skäggnät? Klicka på rätt bilder.`,
			en: `What is the correct way to use hairnet and beard snood? Click on the correct images.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Hair and ears covered, no beard
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Hair, ears and beard covered
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Om ditt skägg är längre än 3 mm måste du använda skäggnät.`,
								en: `If you beard is longer than 3mm, you must wear a beard snood.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // No beard. Not all hair covered. 
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // All hair and ears covered, beard. No beard snood.
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // All hair, but not ears covered. No beard.
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-jewelry',
		layout: 's1-m1-jewelry',
		subtype: 'images',
		background: 'outside',
		backgroundModifier: 'bottom',
		text: {
			se: `Vilka typer av smycken är INTE tillåtna i produktionen?`,
			en: `What kinds of jewellery are NOT allowed in the production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Earring
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Nose piercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Navel piercing
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // Wristwatch
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Du får lov att bära en aktivitetsklocka som SIA gett dig.`,
								en: `You are allowed to wear an activity watch that's been supplied by SIA.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, isCorrect: true, // Necklace
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true, // Wedding ring
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-locker-room',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `God hygien börjar i omklädningsrummet. Se om du kan hitta problemen i nästa bild.`,
			en: `Good hygiene begins in the locker room. See if you can spot the issues on the next image.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-dressing-room-locker',
		layout: 's1-m1-dressing-room-locker',
		background: 'dressing-room',
		backgroundModifier: 'zoom-in',
		text: {
			se: `Klicka på de 5 saker som är fel i bilden. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `Click on the 5 things that are wrong on the image. Click OK when you are ready or if you can't find them all.`
		},
		errors: [ 	 	 
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pickles', // Glass of pickles in private
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja! Inget glas är tillåtet i omklädningsrummet.`,
								en: `Yes! No glass is allowed in the locker room.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'sandwich', // Sandwich (wrapped) in private
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Snyggt! Den här borde läggas i ett kylskåp eller matsalen.`,
								en: `Nice catch! This should be moved to a refrigerator or the cafeteria.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'private-shoes', // Private shoes in private
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja, lämna dem vid ingången.`,
								en: `Yes, leave those at the entrance.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'private-clothes', // Private clothes in work closet
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'work-clothes', // Work clothes on the floor
			})

		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-keep-lockers-clean',
		background: 'dressing-room',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Se till att alltid hålla arbetskläder och privata kläder åtskilda.`,
			en: `Make sure to keep work clothes and private clothes separated at all times.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m1-spot-errors-person',
		layout: 's1-m1-person',
		background: 'dressing-room',
		backgroundModifier: 'lockers-hygiene-lock',
		text: {
			se: `Nu är den här personen klädd för arbetet. Men vad är fel? Klicka på de 4 problemen. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `This person is now dressed for work. But what is wrong? Click on the 4 issues. Click OK when you are ready, or if you can't find them all.`
		},
		errors: [ 	 	 
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'dirt', // Dirt on pants
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'phone', // Cellphone sticking out of pocket
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'coke', // Coke plastic bottle with water in hand
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja, man får bara använda våra flaskor till vatten.`,
								en: `Yes, you should only use our bottles for water.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'hair', // Hair sticking out of hairnet
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-your-responsibility',
		background: 'dressing-room',
		backgroundModifier: 'lockers-hygiene-lock',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Kom ihåg, det är ditt ansvar att hålla dina skåp rena och fina, och att lägga kläder som använts i tvätten.`,
			en: `Remember, it's your responsibility to keep your lockers clean and neat, and to put used clothes in the laundry.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-proceed-to-production',
		background: 'dressing-room',
		backgroundModifier: 'lockers-hygiene-lock',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Okej, nu tror jag att vi är redo att gå vidare till produktionsområdena.`,
			en: `Alright, I think we're ready to proceed to the production areas.`,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m1-order-hygiene-sluice',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Innan man går in i produktionsområdena måste man alltid gå genom en hygiensluss och tvätta händerna. Vilken är rätt ordning att utföra följande åtgärder?`,
			en: `Before entering the production areas, you must always pass through a hygiene sluice and wash your hands. What is the correct order to do the following actions in?`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Tvätta händerna med tvål och vatten i 30 sekunder`,
					en: `Wash hands with soap and water for 30 seconds`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Torka händerna med pappershanddukar`,
					en: `Dry hands with paper towels`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Lägga pappershanddukarna i papperskorgen`,
					en: `Put paper towels in bin`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Använda handsprit`,
					en: `Use sanitizer`
				},
				correctOrderNumbers: [4]
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-nails',
		layout: 's1-m1-nails',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		showRequiredNumberOfAnswers: false,
		text: {
			se: `Naglarna är en viktig del av handhygienen.
				<br /><br />Klicka på rätt bild.`,
			en: `The nails are an important part of hand hygiene. 
				<br /><br />Click the correct image.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Short and clean nail
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Nail with nail polish
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Short, dirty nail
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Artificial nail
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-washing-hands',
		layout: 's1-m1-washing-hands',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Fyra områden är särskilt lätta att glömma bort när man tvättar händerna. Vilka då?`,
			en: `Four areas are especially easy to forget, when washing hands. What are they?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Palm
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Back of hand
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Nails
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Wrist
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Between fingers
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, // Thumb
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, // Pinkie
			}),
		]
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-when-washing-hands',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		shuffleOptions: false,
		showRequiredNumberOfAnswers: false,
		text: {
			se: `Efter vilka situationer måste man komma ihåg att tvätta händerna?
				<br /><br />Välj 1 – och tänk på att läsa alla alternativen:`,
			en: `After which situations must you remember to wash your hands?
				<br /><br />Choose 1 - and remember to read all the options:`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `När man går in i en ny hygienzon`,
					en: 'When entering a new hygiene zone',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Efter att ha rört vid något smutsigt`,
					en: 'After touching something dirty',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Efter att ha plockat upp något från golvet`,
					en: 'After picking up something from the floor',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Efter att ha hanterat allergener`,
					en: 'After handling allergens',
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Efter att ha gått på toaletten`,
					en: 'After being to the toilet',
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `I alla ovanstående situationer`,
					en: 'In all above situations',
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-dirty-things',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Det som anses smutsigt är sådant som handtag, spakar, pallar, förpackningsmaterial, redskap osv.`,
			en: `What is considered dirty are things like handles, switches, pallets, packaging materials, tools, and so on.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-you-are-dirty',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Också du själv är smutsig när det gäller hygienen på Sia. Efter vilka av följande handlingar måste du också tvätta händerna?`,
			en: `You are even dirty yourself, when it comes to hygiene at Sia. After which of the following actions should you also wash your hands?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					se: `Efter att ha kliat sig i håret eller skägget`,
					en: `After scratching hair or beard`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Efter att ha justerat öronmuffarna`,
					en: `After adjusting earmuffs`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Efter att ha knutit skorna`,
					en: `After tying shoelaces`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Efter att ha rört vid fickor`,
					en: `After touching pockets`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Efter att ha justerat skjortan`,
					en: `After adjusting shirt`
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-scratch-wound',
		layout: 's1-m1-scratch-wound',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Vad ska du använda om du får ett rivsår eller annat sår?`,
			en: `If you have a scratch or wound, what should you use?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Blue band aid
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Precis! Våra blåa plåster är lätta att upptäcka. Du ska även bära handske.`,
								en: `Exactly! Our blue band aids are detectable. And you should also wear a glove. `
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Normal band aid
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Cast
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Gauze
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-dry-mats',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Efter att ha tvättat händerna ska du även tvätta skorna på hygienmattorna. Men vad ska du göra om de är torra?`,
			en: `After washing your hands, you should also clean your shoes on the hygiene mats. But what should you do if they are dry?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					se: `Fylla på med tvättmedel`,
					en: `Fill up with detergent`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Hälla en hink vatten på dem`,
					en: `Pour a bucket of water on it`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Inget, så ska de vara`,
					en: `Nothing, that's how they should be`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Få panik`,
					en: `Panic`
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-almost-ready',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Nästan redo att gå in i hygienzonen. Bara några frågor till.`,
			en: `Almost ready to enter the hygiene zone. Just a few more questions.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-allergens',
		layout: 's1-m1-allergens',
		subtype: 'images',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Vad får du aldrig ta med dig in i våra lokaler?`,
			en: `What are you never allowed to bring to our facilities?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, // Peanuts
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, // Chocolate bar with peanuts
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Strawberries
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, // Milk
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Pineapple
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-dont-bring-food',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Du får förstås inte ta med dig någon mat in i en hygienzon, men jordnötter är inte tillåtna i någon form någonstans. Det finns folk som är svårt allergiska mot jordnötter, och minsta spår kan göra dem väldigt sjuka.`,
			en: `Obviously don't bring any food into a hygiene zone, but peanuts are not allowed in any form anywhere. Some people are severely allergic to peanuts, and even a trace of it can make them very sick.`,
		}
	}),	
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m1-mc-stomach-problems',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		text: {
			se: `Vad bör du göra om du har magbesvär och har varit utomlands?`,
			en: `What should you do if you have stomach problems and have been abroad?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, 
				text: {
					se: `Kontakta Avonova via HR och ta ett salmonellatest`,
					en: `Contact Avonova via HR to get a salmonella test`
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Inget om jag mår bra nog att jobba`,
					en: `Nothing if I feel okay to work`
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Sätta ett blått plåster på magen`,
					en: `Wear a blue band aid on my stomach`
				}
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Använda ett blått hårnät`,
					en: `Wear a blue hairnet`
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m1-instructions-completed-first-module',
		background: 'dressing-room',
		backgroundModifier: 'hygiene-lock',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Så ja, nu är du klar med första modulen. Om du har tre stjärnor kan du gå vidare till nästa modul, annars måste du göra om den här modulen en gång till.`,
			en: `That's it, you've completed the first module. If you have three stars, you can continue to the next module. If not, you'll have to take this module once more.`,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m1-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};