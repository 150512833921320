const scenariosData = [
	{
		id: 'scenario-1',
		playerLoginType: 'employee-id',
		title: {
			se: 'Grundläggande hygienutbildning',
			en: 'Basic hygienetraining',
		},
		// languageIds: ['se', 'en'],
		languageIds: ['en'],
		modulesData: require('data/scenarios/scenario-1/modules-data').modulesData,
		moduleGroups: require('data/scenarios/scenario-1/modules-data').moduleGroups,
	},
];

export {
	scenariosData
};