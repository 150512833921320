const taskTemplates = require('data/templates/task-templates');
const {effectTemplate} = require('data/templates/effect-template');

let moduleTasks = [
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-welcome',
		showProgressBar: false,
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Välkommen till den andra delen av hygienutbildningen. Nu ska vi prata om hygien inne i produktionsområdena.`,
			en: `Welcome to the second part of the hygiene training. Now we are going to talk about hygiene inside the production areas.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-handle-product',
		showProgressBar: false,
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Vi kommer även att prata om hur man ska hantera produkten. Alltså vad som är och inte är tillåtet i produktionen.`,
			en: `We'll also be talking about how to handle product. That is, what is allowed and what isn't allowed in production.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-stars',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Då börjar vi! Kom ihåg att du måste få tre stjärnor för att bli klar med modulen.`,
			en: `Let's start! Remember, you must get three stars to complete the module.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-why-aware',
		background: 'production',
		shuffleOptions: false,
		text: {
			se: `Varför måste vi vara särskilt medvetna om hygienen i produktionen?`,
			en: `Why must we be especially aware of hygiene in production?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Sådan är lagen`,
					en: `It's the law`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Eftersom dålig hygien kan göra folk sjuka`,
					en: `Because bad hygiene can make people sick`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `För att säkerställa hög kvalitet`,
					en: `To ensure high quality`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Av alla ovanstående anledningar`,
					en: `For all of the reasons above`,
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-closer-look',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Vi tar en närmare titt på produktionen. Titta på nästa bild och se om du kan hitta saker som kan vara problematiska när det gäller hygienen.`,
			en: `Let's take a closer look at the production. On the next image, see if you can spot the things that can be problematic when it comes to hygiene.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production',
		layout: 's1-m4-production',
		background: 'production',
		text: {
			se: `Klicka på 4 problem. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pallet', // Wooden pallet with stuff placed directly on floor
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja! Träpallen ska placeras på en plastpall.`,
								en: `Yes! The wooden pallet should be placed on a plastic pallet.`
							}
						})
					})
				]
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'soap' // Leftover soap on belt
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'bucket', // Bucket of returmix somewhere with trash in it
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'paint', // Flaking paint on machine
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-returmix',
		background: 'production',
		text: {
			se: `Om vi får skräp i våra hinkar för "returmix" kan det vara ett problem. Varför?`,
			en: `If we get trash in our buckets for "returmix", it can be a problem. Why?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Det kan kontaminera våra produkter`,
					en: `It can contaminate our products`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Det ser inte fint ut`,
					en: `It doesn't look nice`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Det kan göra så att främmande föremål hamnar i våra produkter`,
					en: `It can cause foreign bodies to enter our products`,
				},
				isCorrect: true
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `De faller lättare`,
					en: `They'll fall over more easily`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-close-to-products',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Du befinner dig nära våra produkter varje dag och lägger antagligen märke till sådant som andra kanske förbiser. Om du ser något som kan orsaka problem, gör något åt det. Eller berätta för en produktionsledare.`,
			en: `You are close to our products every day, and you most likely notice things that others might overlook. If you see something, which can cause problems, do something about it. Or tell a production leader.`,
		}
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-contamination',
		background: 'production',
		characters: ['manager pose-2 coat-2 hairnet-1'],
		text: {
			se: `Nu tar vi en titt på kontamination. Kontamination är när saker hamnar i produkterna som inte ska vara där.`,
			en: `Now, let's look at contamination. Contamination is when things get into the products that aren't supposed to be there.`,
		}
	}),

	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-contamination-problem',
		background: 'production',
		text: {
			se: `Våra produkter kan kontamineras på olika sätt. Varför är kontamination ett problem?`,
			en: `Our products can become contaminated in different ways. Why is contamination a problem?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Det kan vara farligt`,
					en: `It can be dangerous`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Det kan förstöra smaken`,
					en: `It can ruin the taste`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Det kan göra kunderna missnöjda`,
					en: `It can cause unhappy customers`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Det släpper ut mer koldioxid`,
					en: `It emits more CO2`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Kontamination är bara ett problem om kvalitetskontrollanterna ser det`,
					en: `Contamination is only an issue if Quality sees it`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Det är olagligt`,
					en: `It's illegal`,
				},
				isCorrect: true
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-soap-belt',
		background: 'production',
		text: {
			se: `I den förra uppgiften fanns det några såpbubblor på bandet. Varför är det ett problem?`,
			en: `In a previous task, there was some soap bubbles on the belt. Why is that a problem?`
		},
		image: 'soap-bubbles',
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Det kan orsaka kemisk kontamination`,
					en: `It can cause chemical contamination`,
				},
				isCorrect: true,
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Precis. Det är därför vi måste vara extra noggranna när vi rengör vår utrustning.`,
								en: `Exactly. That is why we should be extra careful when cleaning our equipment.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Det kan göra så att giftiga gaser uppstår`,
					en: `It can create toxic gasses`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Nej, det är inte ett problem med våra rengöringsmedel.`,
								en: `No, that is not an issue with our detergents.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Någon kan halka på det`,
					en: `A person can slip in it`,
				},
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja, om det hade varit på golvet. Men detta var på bandet, och där ska man inte gå.`,
								en: `If it was on the floor, yes. But this was on the belt, and you shouldn't be walking there.`
							}
						})
					})
				],
			}),
		]
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-allergens',
		layout: 's1-m2-allergens',
		subtype: 'images',
		background: 'production',
		text: {
			se: `Kontamination kan också vara ett problem för folk med allergier. Klicka på fem allergener som vi hanterar på SIA Glass.`,
			en: `Contamination can also cause problems for people with allergies. Click on five allergens we handle at SIA Glass.`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1, isCorrect: true, // Nuts
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2, isCorrect: true, // Milk (kasein)
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3, // Strawberry
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4, isCorrect: true, // wheat (gluten)
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 5, // Orange
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 6, isCorrect: true, // Soya
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 7, isCorrect: true, // Egg
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-worked-with-allergen',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `När du har arbetat med en allergen bör du byta kläder om det går. Om dina kläder kontamineras av en allergen bör du också byta om under en rast.`,
			en: `When you have worked with an allergen, change your clothes if possible. You should also change if your clothes get contaminated by an allergen during a break.`,
		}
	}),
	Object.assign({}, taskTemplates.sortTemplate.mainTemplate, {
		taskId: 's1-m4-sort-tools',
		background: 'production',
		text: {
			se: `Rengöringsredskap på SIA är färgkodade för att visa vad de används till. Sortera följande uppgifter i rätt rutor.`,
			en: `Cleaning tools at SIA is colour coded to show what to use it for. Sort the following tasks into the correct boxes.`
		},
		categories: [
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 1, 
				title: {
					se: `Vita redskap`,
					en: `White tools`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 2, 
				title: {
					se: `Gula redskap`,
					en: `Yellow tools`
				}
			}),
			Object.assign({}, taskTemplates.sortTemplate.categoryTemplate, {
				id: 3, 
				title: {
					se: `Röda redskap`,
					en: `Red tools`
				}
			})
		],
		items: [
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Vanlig rengöring`,
					en: `Ordinary cleaning`
				},
				categoryIds: [1]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Rengöring av allergener`,
					en: `Allergen cleaning`
				},
				categoryIds: [2]
			}),
			Object.assign({}, taskTemplates.sortTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Till avlopp`,
					en: `For drains`
				},
				categoryIds: [3]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-begins-with-you',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `God hygien, och därmed hög kvalitet, börjar hos dig. Se om du kan hitta de problemen på nästa bild.`,
			en: `Good hygiene and thus high quality begins with you. See if you can spot the issues on the next image.`,
		}
	}),
	Object.assign({}, taskTemplates.spotErrorsTemplate.mainTemplate, {
		taskId: 's1-m4-spot-errors-production-2',
		layout: 's1-m2-production-2',
		background: 'production',
		backgroundModifier: 'no-machine',
		text: {
			se: `Klicka på 4 problem. Klicka på OK när du är klar eller om du inte kan hitta alla.`,
			en: `Click on the 4 issues. Click OK when you are ready or if you can't find them all.`
		},
		errors: [
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'open-gate', // Open gate
				effects: [
					Object.assign({}, effectTemplate.mainTemplate, {
						type: 'feedback',
						feedback: Object.assign({}, effectTemplate.feedbackTemplate, {
							text: {
								se: `Ja! Håll portarna stängda så att inte oönskade personer kommer in.
								<br />De har inte vår utbildning, och i värsta fall kan de vilja sabotera för oss.
								<br />Vi vill inte heller bjuda in andra besökare som möss eller fåglar.`,
								en: `Yes! Keep gates closed so that unwanted persons don't get inside.
								<br />They don't have your training, and worst case scenario, they could be looking to sabotage us. 
								<br />We also don't like to invite other visitors, like mice and birds as an example.`
							}
						})
					})
				],
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'clothes', // Hole in clothes with loose thread
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'pallet', // Pallet with stuff still wrapped in plastic
			}),
			Object.assign({}, taskTemplates.spotErrorsTemplate.errorTemplate, {
				id: 'plastic', // Brittle plastic from lamp is found on conveyor for products.
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-pallet-lamp',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Det kan vara värt att ta en närmare titt på två saker från den förra uppgiften: pallen och plastbitarna från en trasig lampa.`,
			en: `Two of the things from the previous task are worth taking a closer look at; the pallet and the plastic pieces from a broken lamp.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-pallet',
		background: 'production',
		text: {
			se: `Pallen borde inte ha plast kring sig. Var ska den avlägsnas?`,
			en: `The pallet should not have plastic around it. Where should that be removed?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `I den grå zonen (zonen mellan lagret och produktionen)`,
					en: `In the grey zone (zone between warehouse and production)`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `I den svarta zonen`,
					en: `In the black zone`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Utanför lokalerna`,
					en: `Outside the facility`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Innan den lämnar lastbilen`,
					en: `Before leaving the truck`,
				},
			}),
		]
	}),	
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-broken-lamp',
		background: 'production',
		text: {
			se: `Om en lampa går sönder i ett produktionsområde, och om det finns en risk att främmande föremål har hamnat i produkten, vad ska du göra då? Placera åtgärderna nedan i rätt ordning.`,
			en: `If a lamp gets broken in a production area, and if there's a risk that foreign bodies have gotten into the product, what should you do? Place the actions below in the right order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Stoppa produktionen`,
					en: `Stop production`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Tillkalla min direkta chef`,
					en: `Call for my immediate manager`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Rengöra området`,
					en: `Clean the area`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Byta om vid behov`,
					en: `Change clothes`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					se: `Vänta på att produktionslinjen frigörs av teamledaren eller chefen för skiftet`,
					en: `Await release of production line from team leader or shift manager`
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-metal-detector',
		background: 'production',
		backgroundModifier: 'metal-detector',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Vi använder även en metalldetektor för att hitta metallbitar i produkterna.`,
			en: `We also use a metal detector to find any metal pieces in our products.`,
		}
	}),
	Object.assign({}, taskTemplates.orderTemplate.mainTemplate, {
		taskId: 's1-m4-order-metal-detector',
		background: 'production',
		backgroundModifier: 'metal-detector',
		text: {
			se: `Du får en signal från metalldetektorn. Det finns en risk att främmande föremål hamnat i produkterna. Vad ska du göra? Placera åtgärderna nedan i rätt ordning.`,
			en: `You get a signal from the metal detector. There is a risk of foreign bodies in the products. What should you do? Place the actions below in the correct order.`
		},
		items: [
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 1,
				text: {
					se: `Köra produkten genom detektorn en gång till och se om den fortfarande ger signal`,
					en: `Run product through detector once again and see if it still gives a signal`
				},
				correctOrderNumbers: [1]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 2,
				text: {
					se: `Undersöka produkterna som har gått igenom`,
					en: `Examine the products that have been pushed out`
				},
				correctOrderNumbers: [2]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 3,
				text: {
					se: `Om metall hittas, tillkalla en chef`,
					en: `If metal is found, summon a manager`
				},
				correctOrderNumbers: [3]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 4,
				text: {
					se: `Lämna in fynden med information om partikod m.m.`,
					en: `Turn in findings with information about batch code etc.`
				},
				correctOrderNumbers: [4]
			}),
			Object.assign({}, taskTemplates.orderTemplate.itemTemplate, {
				id: 5,
				text: {
					se: `Fortsätta arbeta, men vara vaksam`,
					en: `Continue working, but stay alert`
				},
				correctOrderNumbers: [5]
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-few-tasks-left',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Bara några få uppgifter kvar. En av dem handlar om ett annat slags oönskade besökare som kan komma in genom öppna portar och dörrar, nämligen skadedjur.`,
			en: `Only a few tasks left. One of them is about another kind of unwanted visitors that can enter through open gates and doors; namely pests.`,
		}
	}),
	Object.assign({}, taskTemplates.multipleChoiceTemplate.mainTemplate, {
		taskId: 's1-m4-mc-mouse',
		background: 'production',
		text: {
			se: `Du ser en mus på golvet i produktionen. Vad ska du göra?`,
			en: `You spot a mouse on the floor in production. What should you do?`
		},
		options: [
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Ge den en glass`,
					en: `Give it an ice cream`,
				},
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Försöka fånga den`,
					en: `Try to catch it`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Hålla ett öga på den och tillkalla hjälp`,
					en: `Keep an eye on it while calling for assistance`,
				},
				isCorrect: true,
			}),
			Object.assign({}, taskTemplates.multipleChoiceTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Hämta en katt`,
					en: `Get a cat`,
				},
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-finally-survey',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Till sist har vi fyra frågor om kulturen kring livsmedelssäkerhet på SIA. Det finns inga fel eller rätt svar här. Frågorna ger dig inga poäng, och du är anonym när du besvarar dem.`,
			en: `Finally, we have four questions about food safety culture at SIA. There are no right and wrong answers here. The questions don't earn you points, and you answer them anonymously.`,
		}
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-1',
		shuffleOptions: false,
		text: {
			se: `<b>Till vilken grad håller du med om följande påstående?</b>
				<br /><br />Jag har befogenhet och ansvar att reagera om jag ser något som kan utgöra en risk för livsmedelssäkerheten.`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br /><br />I have authority and responsibility to react if I spot something that can be a hazard to food safety.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Håller verkligen med`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Håller med`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Neutral`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Håller inte med`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Håller verkligen inte med`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-2',
		shuffleOptions: false,
		text: {
			se: `<b>Till vilken grad håller du med om följande påstående?</b>
				<br /><br />Jag reagerar om jag ser någon som inte följer riktlinjerna för hygien.`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br /><br />I react if I see someone who isn't following hygiene guidelines.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Håller verkligen med`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Håller med`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Neutral`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Håller inte med`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Håller verkligen inte med`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-3',
		shuffleOptions: false,
		text: {
			se: `<b>Till vilken grad håller du med om följande påstående?</b>
				<br /><br />Om jag ser ett problem med livsmedelssäkerheten löser jag det antingen själv eller informerar min närmaste chef.`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br /><br />If I see a food safety issue, I'll either solve it or inform my closest manager.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Håller verkligen med`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Håller med`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Neutral`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Håller inte med`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Håller verkligen inte med`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.surveyTemplate.mainTemplate, {
		taskId: 's1-m4-survey-4',
		shuffleOptions: false,
		text: {
			se: `<b>Till vilken grad håller du med om följande påstående?</b>
				<br /><br />Om jag rapporterar en livsmedelssäkerhetsrisk kommer något att göras för att ta itu med den.`,
			en: `<b>How strongly do you agree with the following statement?</b>
				<br /><br />If I report a food safety risk, something will be done to handle it.`
		},
		options: [
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 1,
				text: {
					se: `Håller verkligen med`,
					en: `Strongly agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 2,
				text: {
					se: `Håller med`,
					en: `Agree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 3,
				text: {
					se: `Neutral`,
					en: `Neither agree nor disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 4,
				text: {
					se: `Håller inte med`,
					en: `Disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 5,
				text: {
					se: `Håller verkligen inte med`,
					en: `Strongly disagree`
				}
			}),
			Object.assign({}, taskTemplates.surveyTemplate.optionTemplate, {
				id: 6,
				text: {
					se: `Vet inte`,
					en: `Don't know`
				}
			}),
		]
	}),
	Object.assign({}, taskTemplates.instructionsTemplate.mainTemplate, {
		taskId: 's1-m4-instructions-thats-it',
		background: 'production',
		characters: ['manager pose-1 coat-1 hairnet-1'],
		text: {
			se: `Det var allt, nu är du klar med den andra modulen. Om du har tre stjärnor är du klar med hygienutbildningen, annars måste du göra om modulen igen.`,
			en: `That's it, you are now through the second module. If you have three stars, you have completed the hygiene training. If not, you have to retake the module.`,
		}
	}),
];

let taskIdsArr = [];
moduleTasks.forEach((task, index) => {
	task.id = 's1-m4-' + (index + 1 < 10 ? '0' : '') + (index + 1);
	
	/* Check taskId is set when required and unique */
	if (task.isSolveToContinue) {
		if (task.taskId) {
			if (taskIdsArr.indexOf(task.taskId) >= 0) {
				console.error('Task id not unique: ', task.taskId);
			} else {
				taskIdsArr.push(task.taskId);
			}
		} else {
			console.error('Missing task id for task: ', task);
		}
	}
});


export {
	moduleTasks
};