import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyBgAC4ZLgbYtRq7SwTUr8x1skko4ESAJhg',
		authDomain: 'cgl-sia-test.firebaseapp.com',
		projectId: 'cgl-sia-test',
		storageBucket: 'cgl-sia-test.appspot.com',
		messagingSenderId: '1994875170',
		appId: '1:1994875170:web:fb17a4e5ce2c7f1f1fc57b'
	};	
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDYdH8rZu5Y7TT5kgghykntVHxlzImLrG8',
		authDomain: 'cgl-sia-production.firebaseapp.com',
		projectId: 'cgl-sia-production',
		storageBucket: 'cgl-sia-production.appspot.com',
		messagingSenderId: '139389319109',
		appId: '1:139389319109:web:5d45f080c7ac408796bdac'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

export default firebase.firestore;