const moduleImages = [
	'modules/tasks/multiple-choice/s1-m2-allergens/option-1.svg',
	'modules/tasks/multiple-choice/s1-m2-allergens/option-2.svg',
	'modules/tasks/multiple-choice/s1-m2-allergens/option-3.svg',
	'modules/tasks/multiple-choice/s1-m2-allergens/option-4.svg',
	'modules/tasks/multiple-choice/s1-m2-allergens/option-5.svg',
	'modules/tasks/multiple-choice/s1-m2-allergens/option-6.svg',
	'modules/tasks/multiple-choice/s1-m2-allergens/option-7.svg',

	'modules/tasks/spot-errors/s1-m4-production/background.svg',
	'modules/tasks/spot-errors/s1-m2-production-2/background.svg'
];

export {
	moduleImages
};